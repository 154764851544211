<template>
    <sidemenu-layout title="Orders">
        <section class="container max-w-4xl mx-auto p-4 sm:p-12 md:p-20 h-full">
            <header class="hidden lg:flex justify-between items-center space-x-5 mb-10">
                <h1 class="text-2xl lg:text-4xl font-display font-bold">Search Orders</h1>
                <div class="hidden flex-none">
                    <button class="btn btn-primary" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>
            </header>
            
            <div class="space-y-4 pb-16">
                <div class="relative lg:text-2xl rounded-lg border border-solid border-cultured bg-white text-rich-black-fogra-29">
                    <div class="absolute inset-y-0 left-0 flex items-center rounded-lg">
                        <label for="search_type" class="sr-only">Search Type</label>
                        <select id="search_type" v-model="searchType" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-4 border-transparent bg-transparent">
                            <option selected="true">ID</option>
                            <option>Phone</option>
                            <option>Name</option>
                        </select>
                    </div>
                    <input type="text" class="w-full pl-28 lg:pl-40 pr-2 py-2 rounded-lg focus:border-black-coral-lighter focus:ring-0 placeholder-silver-metallic overflow-hidden" placeholder="Search..." @input="debounceSearch($event.target.value)" :value="userInput" />
                </div>
                <div class="rounded-lg border border-solid border-cultured">
                    <div class="flex py-2">
                        <div class="px-2 sm:px-6 py-2 space-x-2 flex items-center cursor-pointer border-r border-solid border-cultured" @click="showFilters = !showFilters">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clip-rule="evenodd" />
                            </svg>
                            <span>Filters</span>
                        </div>
                        <div class="px-2 sm:px-6 flex items-center space-x-2 sm:space-x-4">
                            <div v-if="filterType">
                                <div class="flex flex-wrap items-center">
                                    <span class="inline-flex rounded-full border border-solid border-cultured items-center py-2 pl-3 pr-2 text-sm font-medium bg-cultured text-gray-900">
                                        <span>{{filterType}}</span>
                                        <button  @click="filterType = ''" type="button" class="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500">
                                            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div v-if="filterStatus">
                                <div class="-m-1 flex flex-wrap items-center">
                                    <span class="m-1 inline-flex rounded-full border border-solid border-cultured items-center py-2 pl-3 pr-2 text-sm font-medium bg-cultured text-gray-900">
                                        <span>{{filterStatus}}</span>
                                        <button @click="filterStatus = ''" type="button" class="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-full inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500">
                                            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"></path>
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="!showFilters && 'hidden'" class="border-t border-solid border-cultured mx-2 px-4 py-4 grid grid-cols-1 gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-6">
                        <ion-list>
                            <div class="font-medium">
                                Order Type
                            </div>

                            <ion-radio-group v-model="filterType">
                                <ion-item>
                                    <ion-label>Delivery</ion-label>
                                    <ion-radio value="Delivery"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>PickUp</ion-label>
                                    <ion-radio value="PickUp"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>DineIn</ion-label>
                                    <ion-radio value="DineIn"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </ion-list>

                        <ion-list>
                            <div class="font-medium">
                                Order Status
                            </div>

                            <ion-radio-group v-model="filterStatus">
                                <ion-item>
                                    <ion-label>Confirmed</ion-label>
                                    <ion-radio value="Confirmed"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>Accepted</ion-label>
                                    <ion-radio value="Accepted"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>Completed</ion-label>
                                    <ion-radio value="Completed"></ion-radio>
                                </ion-item>

                                <ion-item>
                                    <ion-label>Declined</ion-label>
                                    <ion-radio value="Declined"></ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </ion-list>
                    </div>
                </div>
                
                <div v-if="loading" class="space-y-4 pt-4 pb-16">
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                    <order-pill-skeleton></order-pill-skeleton>
                </div>
                <div v-else class="space-y-4 pt-4 pb-16">
                    <order-pill v-for="o in results" :key="o.orderID" :record="o"></order-pill>
                </div>
            </div>
        </section>
    </sidemenu-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import SidemenuLayout from '../layouts/SidemenuLayout.vue'
import OrderPill from '../components/OrderPill.vue'
import OrderPillSkeleton from '../components/OrderPillSkeleton.vue'
import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/vue'
import fAPI from '../integration/foodexAPI'
import fUI from '../integration/foodexUI'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'Live',
    components: {
        SidemenuLayout,
        OrderPill,
        OrderPillSkeleton,
        IonItem, 
        IonLabel, 
        IonList, 
        IonRadio, 
        IonRadioGroup,
    },
    setup() {
        const loading = ref(false)
        const showFilters = ref(false)
        const results = ref([])
        const searchType = ref('ID')
        const pageIndex = 0
        const pageSize = 20
        const filters = reactive({
            userInput: '',
            filterType: '',
            filterStatus: ''
        })

        const searchOrders = (keyword: any) => {
            console.log('fire API call with this', keyword)
            loading.value = true
            const orderID = searchType.value == 'ID' ? filters.userInput : '';
            const phone = searchType.value == 'Phone' ? filters.userInput : '';
            const name = searchType.value == 'Name' ? filters.userInput : '';

            fAPI.searchOrders(pageSize, pageIndex, orderID, phone, name, filters.filterStatus, filters.filterType).then((response)=>{
                console.log('getting this back', response.data)
                fUI.toasty(response.data.orders.length + ' orders loaded')
                results.value = response.data.orders
            })
            .catch((authError: AxiosError) => {
                if (authError && authError.response)
                {
                    console.log('Operation failed', authError.response.status)
                    console.log('Error', authError.response)
                    fUI.toasty('Cannot load orders from server. Please try again')
                }
            })
            .finally(() => {
                loading.value = false
                showFilters.value = false
            })
        }

        let searchTimeout: any
        const debounceSearch = (val: any)  => {
            if(searchTimeout) clearTimeout(searchTimeout);

            searchTimeout = setTimeout(function() {
                filters.userInput = val;
                //userInput.value = val; 
                //searchOrders(val)
            }, 400);
        }

        watch(filters, searchOrders)

        return {
            ...toRefs(filters),
            //userInput,
            loading,
            showFilters,
            results,
            searchType,
            // filterType,
            // filterStatus,
            searchOrders,
            debounceSearch,
        }
    },
})
</script>