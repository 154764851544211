
import { defineComponent, reactive, ref, toRefs, watch } from 'vue'
import SidemenuLayout from '../layouts/SidemenuLayout.vue'
import OrderPill from '../components/OrderPill.vue'
import OrderPillSkeleton from '../components/OrderPillSkeleton.vue'
import { IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/vue'
import fAPI from '../integration/foodexAPI'
import fUI from '../integration/foodexUI'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'Live',
    components: {
        SidemenuLayout,
        OrderPill,
        OrderPillSkeleton,
        IonItem, 
        IonLabel, 
        IonList, 
        IonRadio, 
        IonRadioGroup,
    },
    setup() {
        const loading = ref(false)
        const showFilters = ref(false)
        const results = ref([])
        const searchType = ref('ID')
        const pageIndex = 0
        const pageSize = 20
        const filters = reactive({
            userInput: '',
            filterType: '',
            filterStatus: ''
        })

        const searchOrders = (keyword: any) => {
            console.log('fire API call with this', keyword)
            loading.value = true
            const orderID = searchType.value == 'ID' ? filters.userInput : '';
            const phone = searchType.value == 'Phone' ? filters.userInput : '';
            const name = searchType.value == 'Name' ? filters.userInput : '';

            fAPI.searchOrders(pageSize, pageIndex, orderID, phone, name, filters.filterStatus, filters.filterType).then((response)=>{
                console.log('getting this back', response.data)
                fUI.toasty(response.data.orders.length + ' orders loaded')
                results.value = response.data.orders
            })
            .catch((authError: AxiosError) => {
                if (authError && authError.response)
                {
                    console.log('Operation failed', authError.response.status)
                    console.log('Error', authError.response)
                    fUI.toasty('Cannot load orders from server. Please try again')
                }
            })
            .finally(() => {
                loading.value = false
                showFilters.value = false
            })
        }

        let searchTimeout: any
        const debounceSearch = (val: any)  => {
            if(searchTimeout) clearTimeout(searchTimeout);

            searchTimeout = setTimeout(function() {
                filters.userInput = val;
                //userInput.value = val; 
                //searchOrders(val)
            }, 400);
        }

        watch(filters, searchOrders)

        return {
            ...toRefs(filters),
            //userInput,
            loading,
            showFilters,
            results,
            searchType,
            // filterType,
            // filterStatus,
            searchOrders,
            debounceSearch,
        }
    },
})
